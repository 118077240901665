
import { defineComponent } from "vue";
import { msalInstance } from "@/core/config/AuthConfig";
import { msalConfig } from '@/core/config/AuthConfig';
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import JwtService from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";


export default defineComponent({
    setup() {
        const store = useStore();
        const router = useRouter();

        msalInstance.loginPopup()
            .then((loginResponse) => {
                // login response handling code here
                // console.log("Login response:", loginResponse);

                if (loginResponse.account) {
                    // const store = useStore();
                    // const router = useRouter();
                    const activeAccount = msalInstance.getAccountByHomeId(loginResponse.account.homeAccountId);
                    msalInstance.setActiveAccount(activeAccount);

                    msalInstance.acquireTokenSilent({ scopes: ["api://" + msalConfig.auth.clientId + "/login"] })
                        .then((tokenResponse) => {
                            const ssoToken = tokenResponse.accessToken;
                            JwtService.saveToken("Bearer " + ssoToken)
                            // console.log(store.getters.currentSettings.is_sso_activated)
                            // console.log("SSO Token Saved")
                            // console.log(JwtService.getToken())
                            setTimeout(() => {
                                // Send login request
                                store
                                    .dispatch(Actions.LOGIN_SSO, JwtService.getToken())
                                    .then(() => {
                                        router.push({ name: "dashboard" });
                                    })
                                    .catch((error) => {
                                        let errorMsg = "Une erreur est survenue. Veuillez réessayer dans un instant.";

                                        // if (store.getters.getErrors.global) {
                                        //     errorMsg = store.getters.getErrors.global;
                                        // }
                                        console.log(error)
                                        Swal.fire({
                                            text: errorMsg,
                                            icon: "error",
                                            buttonsStyling: false,
                                            confirmButtonText: "Fermer",
                                            customClass: {
                                                confirmButton: "btn fw-bold btn-light-danger",
                                            },
                                        });
                                    });

                            }, 2000);

                        })
                        .catch((error) => {
                            // Handle acquireTokenSilent error
                            console.error("Acquire token error:", error);
                        });
                } else {
                    console.error("No account object provided in login response.");
                }

            })
            .catch((error) => {
                // console.log("first catch is up")
                // Handle login error
                console.log(error);
            });
    }


});
